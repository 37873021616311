import { ILessonRequest } from '@theplaza/types';
import { useState } from 'react';

import { api } from '../plugins/axios';

const useLessonRequestResponseDelete = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const deleteResponse = async (responseId: string | number) => {
    setLoading(true);

    try {
      await api.delete<ILessonRequest>(
        `/lesson_request_responses/${responseId}`
      );

      return;
    } catch (error) {
      throw new Error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { deleteResponse, loading };
};

export default useLessonRequestResponseDelete;
