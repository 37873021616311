import { ILessonRequest } from '@theplaza/types';
import { useState } from 'react';

import { api } from '../plugins/axios';

const useLessonRequestResponseUpdate = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const updateResponse = async (responseId: string | number, body: string) => {
    setLoading(true);

    try {
      const lessonRequestResponse = (
        await api.put<ILessonRequest>(
          `/lesson_request_responses/${responseId}`,
          {
            lesson_request_response: {
              body,
            },
          }
        )
      ).data;

      return lessonRequestResponse;
    } catch (error) {
      throw new Error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { updateResponse, loading };
};

export default useLessonRequestResponseUpdate;
