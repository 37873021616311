import { useCallback, useEffect, useState } from 'react';
import { ILessonRequest, UserType } from '@theplaza/types';

import { api } from '../plugins/axios';
import lessonRequestToPost from '../lessonRequestToPost';
import { useUser } from '../contexts/UserContext';
import { Item } from '../types';
import { resetPostSession } from '../utils';

const START = 1;
const PER = 12;
const URLS = {
  list: '/lesson_requests',
  myList: '/me/lesson_requests',
};

const useLessonRequestList = (
  {
    excludeFilter = {},
    filterParams = {},
    type = 'list',
    requesterUserType = 'student',
    isFilterInitialized = false,
  }: {
    excludeFilter?: { closed?: boolean };
    filterParams?: { [key: string]: string };
    type?: 'list' | 'myList';
    requesterUserType?: UserType | 'tutor';
    isFilterInitialized?: Boolean;
  } = {
    excludeFilter: {},
    filterParams: {},
    type: 'list',
  }
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [posts, setPosts] = useState<Item[]>([]);
  const [page, setPage] = useState(START);
  const [yOffset, setYOffset] = useState(0);

  const { id: userId } = useUser();
  const url = URLS[type];

  const loadPosts = useCallback(() => {
    return api.get<ILessonRequest[]>(url, {
      params: {
        requester_user_type: requesterUserType,
        'filter[closed]': excludeFilter.closed ? '0' : undefined,
        ...filterParams,
        per: PER,
        page: page,
      },
    });
  }, [page, excludeFilter.closed, filterParams, requesterUserType, url]);

  useEffect(() => {
    if (type === 'list' && !isFilterInitialized) return;

    if (page === START) {
      setIsLoading(true);
    } else {
      setIsLoadingMore(true);
    }

    const storedYOffset = sessionStorage.getItem('yOffset');
    const storedPosts = sessionStorage.getItem('posts');
    if (storedYOffset && storedPosts) {
      setYOffset(parseInt(storedYOffset));
      const parsedPosts = JSON.parse(storedPosts);
      setPosts(parsedPosts);
      setIsLoading(false);
      resetPostSession();
      return;
    }

    loadPosts()
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        throw new Error('데이터 로딩 중 오류가 발생했습니다.');
      })
      .then((data) => {
        if (page === START) {
          const newPosts: Item[] = data.map(lessonRequestToPost(userId));
          setPosts(newPosts);
          return;
        }

        if (data.length === 0) {
          alert('더 이상 데이터가 없습니다.');
          return;
        }

        const newPosts: Item[] = data.map(lessonRequestToPost(userId));
        setPosts((prev) => [...prev, ...newPosts]);
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally(() => {
        if (page === START) {
          setIsLoading(false);
        } else {
          setIsLoadingMore(false);
        }
      });
  }, [page, loadPosts, userId, isFilterInitialized, type]);

  const loadMore = () => {
    setPage((prev) => prev + 1);
  };

  return {
    isLoading,
    isLoadingMore,
    loadMore,
    posts,
    yOffset,
  };
};

export default useLessonRequestList;
