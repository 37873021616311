import { TutorGenderType, LessonRequestCategory } from '@theplaza/types';

export const REGION = {
  all: '전체',
  seoul: '서울',
  gyunggi: '경기',
  busan: '부산',
  field: '필드',
};

export const GENDER_TYPE: { [k in TutorGenderType]: string } = {
  all: '성별무관',
  male: '남자',
  female: '여자',
};

export const CATEGORY: { [k in LessonRequestCategory]: string } = {
  all: '전체',
  pro: '골프',
  'pilates-pro': '필라테스',
  'pt-pro': 'PT',
};

export const SCREEN = {
  tutor: "tutor",
  user: "user",
}