import { ILessonRequest } from '@theplaza/types';
import { useState } from 'react';

import { api } from '../plugins/axios';

const useLessonRequestResponseCreate = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const createResponse = async (
    lessonRequestId: string | number,
    body: string
  ) => {
    setLoading(true);

    try {
      const lessonRequestResponse = (
        await api.post<ILessonRequest>(
          `/lesson_requests/${lessonRequestId}/responses`,
          {
            lesson_request_response: {
              body,
            },
          }
        )
      ).data;

      return lessonRequestResponse;
    } catch (error) {
      throw new Error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { createResponse, loading };
};

export default useLessonRequestResponseCreate;
