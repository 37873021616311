import { useState } from 'react';
import axios from 'axios';

import { getExtensionFromPath } from '../utils';
import { api } from '../plugins/axios';

const useImageUpload = () => {
  const [isUploading, setIsUploading] = useState(false);

  const fetchPresignedUrl = async (image: File): Promise<string> => {
    const { data } = await api.post<{ presigned_url: string }[]>(
      'presigned_urls',
      {
        presigned_url: {
          file_infos: [
            {
              extension: getExtensionFromPath(image.name),
            },
          ],
        },
      }
    );

    return data[0].presigned_url;
  };

  const uploadImage = async ({
    image,
    presignedUrl,
  }: {
    image: File;
    presignedUrl: string;
  }): Promise<void> => {
    await axios.put(presignedUrl, image, {
      headers: {
        'Content-Type': image.type,
      },
    });
  };

  const handleImageChange = async (image?: File) => {
    if (!image) {
      return;
    }

    setIsUploading(true);

    try {
      const presignedUrl = await fetchPresignedUrl(image);
      await uploadImage({ image, presignedUrl });

      return presignedUrl.split('?')[0];
    } catch (error) {
      alert((error as Error).message);
    } finally {
      setIsUploading(false);
    }
  };

  return { handleImageChange, isUploading };
};

export default useImageUpload;
