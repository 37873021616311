import { ILessonRequest } from '@theplaza/types';
import { useCallback, useEffect, useState } from 'react';
import lessonRequestToPost from '../lessonRequestToPost';

import { api } from '../plugins/axios';

const useLessonRequest = (userId: number, id?: string) => {
  const [lessonRequest, setLessonRequest] = useState<ILessonRequest | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const post = lessonRequest
    ? lessonRequestToPost(userId)(lessonRequest)
    : null;

  const fetchLesson = useCallback(async () => {
    setLoading(true);

    try {
      const lessonRequest = (
        await api.get<ILessonRequest>(`/lesson_requests/${id}`)
      ).data;

      setLessonRequest(lessonRequest);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchLesson();
  }, [fetchLesson, id]);

  return { lessonRequest, post, error, loading, fetchLesson };
};

export default useLessonRequest;
