import React, { useEffect, useState } from 'react';
import { Topbar } from '../../components/Topbar';
import { ReactComponent as Lock } from '../../assets/svg/lock_icon.svg';
import { H3 } from '../../components/H3';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { VolunteerCard } from '../../components/VolunteerCard';
import { useHistory, useParams } from 'react-router';
import { Button } from '../../components/Button';
import { TutorProposalAddModal } from '../../components/TutorProposalAddModal';
import {
  isVideo,
  parseNewLine,
  resetPostSession,
  sanitizeTag,
} from '../../utils';
import useLessonRequest from '../../hooks/useLessonRequest';
import { useUser } from '../../contexts/UserContext';
import { TutorProposalModal } from '../../components/TutorProposalModal';
import useLessonRequestResponseDelete from '../../hooks/useLessonRequestResponseDelete';
import { Volunteer } from '../../types';
import { TutorProposalEditModal } from '../../components/TutorProposalEditModal';
import { isAfter } from 'date-fns';
import { BottomBar } from '../../components/BottomBar';
import { api } from '../../plugins/axios';

export const UserPostDetail = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);
  const [isTutorProposalModalOpen, setIsTutorProposalModalOpen] =
    useState(false);
  const [isOngoing, setIsOngoing] = useState(true);
  const [isPostLoaded, setIsPostLoaded] = useState(false);

  const params = useParams<{ id?: string }>();
  const { push } = useHistory();

  const { id: userId, isTutor, isStudent } = useUser();
  const { post, fetchLesson } = useLessonRequest(userId, params.id);
  const { deleteResponse } = useLessonRequestResponseDelete();
  const isRequestDisabled = post
    ? isAfter(new Date(), new Date(post!.closedAt as string))
    : false;

  const openModal = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const closeAddModalAfterSuccess = () => {
    setIsAddModalOpen(false);
    fetchLesson();

    setIsSuccessToastOpen(true);

    setTimeout(() => {
      setIsSuccessToastOpen(false);
    }, 2000);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const closeEditModalAfterSuccess = () => {
    setIsEditModalOpen(false);
    fetchLesson();
  };

  useEffect(() => {
    if (post && !isPostLoaded) {
      setIsOngoing(post.isOngoing);
      setIsPostLoaded(true);
    }
  }, [post, isPostLoaded]);

  if (!post) {
    return null;
  }

  const isVideoFile = isVideo(post.filePath);

  const shouldDisplayResponseButton = () => {
    return isTutor && post.isOngoing;
  };

  const myResponse = post.volunteer?.find((v) => v.user?.id === userId);

  const shouldDisplayMyResponseButton = () => {
    return !!myResponse;
  };

  const onDeleteResponse = (response: Volunteer) => async () => {
    const result = window.confirm('정말 삭제하시겠습니까?');

    if (result) {
      await deleteResponse(response.id);

      fetchLesson();
    }
  };

  const onEditResponse = () => {
    setIsTutorProposalModalOpen(false);
    setIsEditModalOpen(true);
  };

  const onClickEdit = () => {
    if (isRequestDisabled) {
      alert('일주일이 지나 마감된 포스팅입니다. 레슨요청을 새로 작성해주세요.');
      return;
    }
    push(`/post/user/${post.id}/edit`);
  };

  const onClickClose = async () => {
    if (isOngoing) {
      const result = window.confirm('정말 마감하시겠습니까?');
      if (!result) return;
    }
    changePostState(isOngoing);
  };

  const changePostState = async (isOngoing: boolean) => {
    try {
      await api.put(`/lesson_requests/${post.id}`, {
        lesson_request: { closed: isOngoing, requester_id: userId },
      });
      setIsOngoing(!isOngoing);
      resetPostSession();
    } catch (error) {
      alert('에러가 발생했습니다. ' + (error as Error).message);
      return;
    }
  };

  return (
    <div className="pb-20">
      <TutorProposalAddModal
        open={isAddModalOpen}
        onClose={closeAddModal}
        onSuccess={closeAddModalAfterSuccess}
        lessonRequestId={params.id}
      />

      <TutorProposalEditModal
        open={isEditModalOpen}
        onClose={closeEditModal}
        onSuccess={closeEditModalAfterSuccess}
        initialValue={myResponse}
      />

      <Topbar text="상세보기" back={true} />

      <div className="p-4 space-y-5">
        <div>
          <div className="flex items-center space-x-2 text-xs mb-2">
            <label
              className={`px-3 py-1.5 rounded ${
                isOngoing
                  ? 'bg-brand-1 text-white'
                  : 'bg-gray-200 text-gray-600'
              }`}
            >
              {isOngoing ? '모집중' : '마감'}
            </label>
            {post.hasPost && (
              <label className="px-3 py-1.5 bg-black text-white rounded">
                내가쓴 글
              </label>
            )}
            {post.private && isStudent && <Lock />}
          </div>
          <H3>{post.title}</H3>
          <div className="text-sm text-brand-gray-2">
            {utcToLocalFormat(
              post.createdAt.toLocaleString(),
              MomentFormat.YYYYMMDD
            )}
          </div>
        </div>

        <div className="text-brand-1 text-sm">
          <div>
            {post.category} | {post.area} | {post.gender}
          </div>
          <div>비용 : {post.price}</div>
        </div>

        <div
          dangerouslySetInnerHTML={{
            __html: parseNewLine(sanitizeTag(post.description)),
          }}
        />
      </div>

      {isVideoFile ? (
        <div className="relative w-full">
          <video src={`${post.filePath}#t=0.1`} controls={true} />
        </div>
      ) : (
        post.filePath && (
          <div className="relative w-full">
            <img src={post.filePath} alt="" />
          </div>
        )
      )}
      {!post.filePath && <div className="h-2 bg-gray-100" />}
      <div className="mt-3">
        <div className="border-b py-3 mx-4">
          총 {post.volunteer ? post.volunteer.length : 0}명의 제안
        </div>

        {/* 일반유저+본인 게시글일 경우만 제안 내용 확인 가능 */}
        {userId === post.requesterId &&
          post.volunteer?.map((vol) => <VolunteerCard item={vol} />)}

        {!post.volunteer && (
          <div className="text-center mt-10 text-sm text-gray-400">
            현재 제안 받은 내용이 없습니다.
          </div>
        )}
      </div>
      {post.hasPost && (
        <BottomBar
          isClosed={!isOngoing}
          closedAt={post.closedAt}
          onClickClose={onClickClose}
          onClickEdit={onClickEdit}
          isRequestDisabled={isRequestDisabled}
        />
      )}

      {shouldDisplayMyResponseButton() ? (
        <div className="fixed left-0 bottom-4 px-4 w-full">
          <Button
            text="내 제안서 보기"
            className="filled-brand-black w-full"
            onClick={() => setIsTutorProposalModalOpen(true)}
          />
        </div>
      ) : shouldDisplayResponseButton() ? (
        <div className="fixed left-0 bottom-4 px-4 w-full">
          <Button
            text="레슨 제안하기"
            className="filled-brand-1 w-full"
            onClick={openModal}
          />
        </div>
      ) : null}

      {isSuccessToastOpen ? (
        <div className="fixed left-0 top-20 px-4 w-full">
          <div className="bg-white text-brand-1 p-4 rounded-lg shadow text-center font-semibold">
            레슨 제안이 완료되었습니다.
          </div>
        </div>
      ) : null}

      {myResponse ? (
        <TutorProposalModal
          open={isTutorProposalModalOpen}
          onClose={() => setIsTutorProposalModalOpen(false)}
          item={myResponse}
          onDelete={onDeleteResponse(myResponse)}
          onEdit={onEditResponse}
        />
      ) : null}
    </div>
  );
};
