import { useHistory, useParams } from 'react-router';

import { Topbar } from '../../components/Topbar';
import useLessonRequest from '../../hooks/useLessonRequest';
import UserPostForm, { INewLessonRequest } from './UserPostForm';
import { api } from '../../plugins/axios';
import { useUser } from '../../contexts/UserContext';
import Loading from '../../components/Loading';
import useImageUpload from '../../hooks/useImageUpload';
import { resetPostSession } from '../../utils';

const DISABLED_ATTRS = new Set<keyof INewLessonRequest>(['terms_agreed_at']);

export const EditUserPost = () => {
  const params = useParams<{ id?: string }>();
  const { id: requester_id } = useUser();
  const { lessonRequest } = useLessonRequest(requester_id, params.id);
  const { push } = useHistory();
  const { handleImageChange, isUploading } = useImageUpload();

  if (!lessonRequest) {
    return <Loading />;
  }

  const submit = async (newLessonRequested: INewLessonRequest) => {
    try {
      await api.put(`/lesson_requests/${params.id}`, {
        lesson_request: { ...newLessonRequested, requester_id },
      });
    } catch (error) {
      alert('저장 중 에러가 발생했습니다. ' + (error as Error).message);
      return;
    }

    resetPostSession();
    push('/post/user');
  };

  const initialValues: INewLessonRequest = {
    title: lessonRequest.title,
    body: lessonRequest.body,
    category: lessonRequest.category,
    region: lessonRequest.region,
    tutor_gender: lessonRequest.tutor_gender,
    price: lessonRequest.price,
    private: lessonRequest.private,
    closed: lessonRequest.closed,
    terms_agreed_at: lessonRequest.terms_agreed_at,
    image_url: lessonRequest.image_url,
  };

  return (
    <>
      <Topbar text="포스팅 수정" back={true} />

      <UserPostForm
        onSubmit={submit}
        submitButtonText="저장하기"
        initialValues={initialValues}
        disabledAttrs={DISABLED_ATTRS}
        onImageChange={handleImageChange}
        isImageUploading={isUploading}
      />
    </>
  );
};
