import { UserType } from '@theplaza/types';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Admin } from './admin/Admin';
import { AdminRoute } from './components/AdminRoute';
import { ScrollToTop } from './components/ScrollToTop';
import UserContext, { IUser } from './contexts/UserContext';
import { Router } from './pages/Router';
import { setupApi } from './plugins/axios';

function App() {
  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    const userId = Number(localStorage.getItem('userId') || 0);
    const userType = (localStorage.getItem('userType') ||
      'student') as UserType;
    const newUser = {
      id: userId,
      type: userType,
      isTutor: userType !== 'student',
      isStudent: userType === 'student',
    };

    setUser(newUser);
  }, []);

  useEffect(() => {
    setupApi();
  }, []);

  return (
    <UserContext.Provider value={user}>
      <BrowserRouter>
        <ScrollToTop />

        <Switch>
          <AdminRoute path="/admin" component={Admin} />
          {user && <Route path="/" component={Router} />}
        </Switch>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
