import React from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as Lock } from '../assets/svg/lock_icon.svg';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { Item } from '../types';
import { isVideo } from '../utils';
import { useUser } from '../contexts/UserContext';

interface TutorPostCardProps {
  item: Item;
}

const DEFAULT_VIDEO_THUMBNAIL_URL =
  'https://s3.ap-northeast-2.amazonaws.com/tpzstudio-test/fallbacks/user/thumbnail_avatar2.jpg';
const PlayIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
  </svg>
);

export const TutorPostCard: React.FC<TutorPostCardProps> = ({ item }) => {
  const { push } = useHistory();
  const { isTutor } = useUser();

  const isVideoFile = isVideo(item.filePath);

  const onPostCardClick = () => {
    if (!item.hasPost && item.private && isTutor) {
      alert('비밀글로 작성자만 볼 수 있습니다');
    } else if (!item.hasPost && !item.isOngoing) {
      alert('이미 마감된 글입니다');
    } else {
      sessionStorage.setItem('yOffset', window.pageYOffset.toString());
      push(`/post/tutor/${item.id}`);
    }
  };

  return (
    <>
      <div
        className="px-4 py-5 border-b border-gray-100 text-sm flex"
        onClick={onPostCardClick}
      >
        <div className="wh-24 relative overflow-hidden rounded-md mr-3 flex-shrink-0">
          <img
            src={
              isVideoFile
                ? item.videoThumbnailPath || DEFAULT_VIDEO_THUMBNAIL_URL
                : item.filePath
            }
            alt=""
            className="w-full h-full absolute object-cover"
          />

          {isVideoFile && (
            <div className="absolute w-full h-full flex items-center place-content-center bg-opacity-20 bg-black">
              <PlayIcon className="fill-current text-white h-12 w-12 opacity-90" />
            </div>
          )}
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div>
            <div className="text-sm leading-normal">{item.requester.name}</div>
            <div className="flex justify-between items-center">
              <div className="text-base font-bold">{item.title}</div>
              {item.private && isTutor && <Lock />}
            </div>
          </div>
          <div className="flex justify-between items-end text-xs">
            <div className="leading-normal">
              <div className="text-brand-gray-2">
                {item.category} | {item.area}
              </div>
              <div className="text-brand-gray-2">
                {utcToLocalFormat(
                  item.createdAt.toLocaleString(),
                  MomentFormat.YYYYMMDD
                )}
              </div>
            </div>
            <div className="flex justify-end space-x-2 min-w-16">
              <label
                className={`px-3 py-1.5 rounded ${
                  item.isOngoing
                    ? 'bg-brand-1 text-white'
                    : 'bg-gray-200 text-gray-600'
                }`}
              >
                {item.isOngoing ? '모집중' : '마감'}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
