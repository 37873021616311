import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export interface TabProps {
  text: string;
  to: string;
  selected: boolean;
  className?: string;
}

export const Tab: FC<TabProps> = ({ text, to, selected, className }) => {
  return (
    <Link
      to={to}
      className={`py-2 ${className} text-15 text-center flex-1 ${
        selected
          ? 'font-bold border-b-2 border-brand-1 text-brand-1'
          : 'text-gray-400 border-b'
      }`}
    >
      {text}
    </Link>
  );
};
