import React, { InputHTMLAttributes } from 'react';
import { Checkbox } from './Checkbox';
import { ReactComponent as FilterIcon } from '../assets/svg/filter_icon.svg';
import { ReactComponent as FilterActiveIcon } from '../assets/svg/filter_icon_active.svg';

export interface Filter {
  closed?: boolean;
}

interface FilterBarProps {
  onClosedChange: InputHTMLAttributes<HTMLInputElement>['onChange'];
  onClick: () => void;
  filter: Filter;
  isFilterApplied: Boolean;
}

interface FilterButtonProps {
  onClick: () => void;
}

const FilterAppliedButton: React.FC<FilterButtonProps> = ({ onClick }) => {
  return (
    <div>
      <button
        className="flex items-center bg-brand-1 text-white px-2.5 py-1.5 rounded text-15"
        onClick={onClick}
      >
        <FilterActiveIcon />
        <div className="ml-1">필터 적용됨</div>
      </button>
    </div>
  );
};

const FilterDefaultButton: React.FC<FilterButtonProps> = ({ onClick }) => {
  return (
    <button
      className="flex items-center outlined-brand-1 px-2.5 py-1.5 rounded text-15"
      onClick={onClick}
    >
      <FilterIcon />
      <div className="ml-1">필터</div>
    </button>
  );
};

export const FilterBar: React.FC<FilterBarProps> = ({
  filter,
  onClosedChange,
  onClick,
  isFilterApplied,
}) => {
  return (
    <div className="px-4 h-14 py-3 flex justify-between items-center border-b sticky top-14 bg-white z-10">
      <div className="">
        <Checkbox
          label="마감글 안보기"
          onChange={onClosedChange}
          checked={filter.closed === true}
        />
      </div>
      {isFilterApplied ? (
        <FilterAppliedButton onClick={onClick} />
      ) : (
        <FilterDefaultButton onClick={onClick} />
      )}
    </div>
  );
};
