import React from 'react';
import { Button } from './Button';

interface BottomBarProps {
  isClosed: boolean;
  closedAt: string | null;
  onClickEdit: () => void;
  onClickClose: () => void;
  isRequestDisabled: boolean;
}

export const BottomBar: React.FC<BottomBarProps> = ({
  isClosed,
  onClickEdit,
  onClickClose,
  isRequestDisabled,
}) => {
  return (
    <div className="flex flex-column justify-between fixed w-full bottom-0 text-white bg-white shadow z-10 p-4">
      <Button text={'글 수정'} onClick={onClickEdit} className={'bg-black'} />
      <Button
        text={isClosed ? '마감하기 해제' : '마감하기'}
        onClick={onClickClose}
        className={`flex-1 ml-4 ${
          isRequestDisabled ? 'bg-gray-400' : 'bg-brand-1'
        }`}
        disabled={isRequestDisabled}
      />
    </div>
  );
};
