import React, { useState } from 'react';

import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { User } from '../types';
import { parseNewLine, sanitizeTag } from '../utils';
import { Button } from './Button';
import { TutorProposalModal } from './TutorProposalModal';

interface Item {
  id: number;
  user?: User;
  createdAt: string;
  description: string;
}

interface VolunteerCardProps {
  item: Item;
}

export const VolunteerCard: React.FC<VolunteerCardProps> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!item.user) {
    return null;
  }

  return (
    <>
      <TutorProposalModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        item={item}
      />
      <div className="mx-4 py-5 border-b">
        <div className="flex items-center">
          <div className="wh-12 rounded-full overflow-hidden relative">
            <img
              src={item.user.imagePath}
              alt=""
              className="w-full h-full absolute object-cover"
            />
          </div>
          <div className="ml-3">
            <div className="text-sm font-medium">{item.user.name}</div>
            <div className="text-xs text-brand-gray-1">
              {utcToLocalFormat(
                item.createdAt.toLocaleString(),
                MomentFormat.YYYYMMDD
              )}
            </div>
          </div>
        </div>
        <div
          className="text-sm font-medium my-3 truncate-3-lines"
          dangerouslySetInnerHTML={{
            __html: parseNewLine(sanitizeTag(item.description)),
          }}
        />
        <Button
          text="내용 전체보기"
          className="filled-gray-100 text-brand-black w-full"
          onClick={() => setIsOpen(true)}
        />
      </div>
    </>
  );
};
