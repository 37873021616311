import { UserType } from '@theplaza/types';
import { createContext, useContext } from 'react';

export interface IUser {
  id: number;
  type: UserType;
  isTutor: boolean;
  isStudent: boolean;
}

const UserContext = createContext<IUser | undefined>(undefined);

const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};

export { useUser };
export default UserContext;
