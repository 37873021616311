import React from 'react';
import { useHistory } from 'react-router';
import { Icon } from './Icon';

interface TopbarProps {
  text: string;
  back?: boolean;
  rightText?: string;
  rightOnClick?: () => void;
}

export const Topbar: React.FC<TopbarProps> = ({
  text,
  back = false,
  rightText,
  rightOnClick,
}) => {
  const history = useHistory();
  const handleGoBack = () => {
    if (history.length === 1) {
      history.push('/');
    } else {
      history.goBack();
    }
  };
  return (
    <div className="sticky w-full h-14 top-0 bg-brand-black text-white grid grid-cols-3 items-center z-20 px-4">
      {back && <Icon.ChevronLeft onClick={handleGoBack} />}
      <div className="text-center col-start-2">{text}</div>
      {rightText && (
        <div className="text-right text-sm" onClick={rightOnClick}>
          {rightText}
        </div>
      )}
    </div>
  );
};
