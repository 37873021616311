import React, { useEffect, useState } from 'react';
import { Chip } from './Chip';
import { H1 } from './H1';
import { H2 } from './H2';
import { H3 } from './H3';
import { Icon } from './Icon';
import { Button } from './Button';
import { REGION, CATEGORY, GENDER_TYPE, SCREEN } from '../constants';

export interface FilterModalProps {
  open: boolean;
  onFilterChange: (filters: {
    areaTags: string[];
    categoryTags: string[];
    genderTags: string[];
  }) => void;
  onClose: () => void;
  screen: string;
}

export const convertFilterToParams = (filter: {
  areaTags: string[];
  categoryTags: string[];
  genderTags: string[];
}) => {
  const params: { [key: string]: string } = {};
  const regionFilter = filter.areaTags
    .filter((tag) => tag !== 'all')
    .map((key) => REGION[key as keyof typeof REGION])
    .join(',');
  const categoryFilter = filter.categoryTags
    .filter((tag) => tag !== 'all')
    .join(',');
  const genderFilter = filter.genderTags
    .filter((tag) => tag !== 'all')
    .join(',');

  if (regionFilter) {
    params['filter[region]'] = regionFilter;
  }

  if (categoryFilter) {
    params['filter[category]'] = categoryFilter;
  }

  if (genderFilter) {
    params['filter[tutor_gender]'] = genderFilter;
  }
  return params;
};

export const getFilterFromStorage = (screen = SCREEN.user) => {
  const storedFilterData = JSON.parse(
    localStorage.getItem(getFilterName(screen)) || '{}'
  );
  const selectedAreaTags = storedFilterData.areaTags
    ? new Set<string>(storedFilterData.areaTags)
    : new Set(['all']);
  const selectedCategoryTags = storedFilterData.categoryTags
    ? new Set<string>(storedFilterData.categoryTags)
    : new Set(['all']);
  const selectedGenderTags = storedFilterData.genderTags
    ? new Set<string>(storedFilterData.genderTags)
    : new Set(['all']);

  const filterData = {
    areaTags: Array.from(selectedAreaTags),
    categoryTags: Array.from(selectedCategoryTags),
    genderTags: Array.from(selectedGenderTags),
  };

  return filterData;
};

const getFilterName = (screen: string) => {
  return screen + 'filter';
};

export const FilterModal: React.FC<FilterModalProps> = ({
  open,
  onClose,
  onFilterChange,
  screen,
}) => {
  const [selectedAreaTags, setSelectedAreaTags] = useState<Set<string>>(
    new Set<string>(['all'])
  );
  const [selectedCategoryTags, setSelectedCategoryTags] = useState(
    new Set<string>(['all'])
  );
  const [selectedGenderTags, setSelectedGenderTags] = useState(
    new Set<string>(['all'])
  );

  useEffect(() => {
    const filterData = JSON.parse(
      localStorage.getItem(getFilterName(screen)) || '{}'
    );

    if (filterData.areaTags) {
      setSelectedAreaTags(new Set(filterData.areaTags));
    }
    if (filterData.categoryTags) {
      setSelectedCategoryTags(new Set(filterData.categoryTags));
    }
    if (filterData.genderTags) {
      setSelectedGenderTags(new Set(filterData.genderTags));
    }
  }, [screen]);

  const handleAreaTagClick = (area: string) => () => {
    if (area === 'all') {
      setSelectedAreaTags(new Set(['all']));
      return;
    }

    const newValue = new Set(selectedAreaTags);
    newValue.delete('all');

    if (selectedAreaTags.has(area)) {
      newValue.delete(area);
    } else {
      newValue.add(area);
    }

    setSelectedAreaTags(newValue);
  };

  const handleCategoryTagClick = (category: string) => () => {
    setSelectedCategoryTags(new Set([category]));
  };

  const handleGenderTagClick = (gender: string) => () => {
    setSelectedGenderTags(new Set([gender]));
  };

  const resetAll = () => {
    setSelectedAreaTags(new Set(['all']));
    setSelectedCategoryTags(new Set(['all']));
    setSelectedGenderTags(new Set(['all']));
  };

  const apply = () => {
    const filterData = {
      areaTags: Array.from(selectedAreaTags),
      categoryTags: Array.from(selectedCategoryTags),
      genderTags: Array.from(selectedGenderTags),
    };

    onFilterChange(filterData);

    localStorage.setItem(getFilterName(screen), JSON.stringify(filterData));

    onClose();
  };

  return (
    <>
      {open && (
        <>
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity z-20"
            aria-hidden="true"
            onClick={onClose}
          ></div>
          <div className="bg-white p-5 w-full fixed bottom-0 z-20 rounded-t-lg shadow-2xl">
            <div className="flex items-center justify-between mt-3">
              <div className="flex space-x-3 items-center">
                <H1 className="">
                  {screen === SCREEN.user ? '레슨 요청 필터' : '레슨 모집 필터'}
                </H1>
                <H3 className="text-brand-1" onClick={resetAll}>
                  초기화
                </H3>
              </div>
              <Icon.X onClick={onClose} />
            </div>

            <div className="">
              <>
                <div className="flex items-center space-x-2 mt-7">
                  <H2>지역 선택</H2>
                  <div className="text-sm">
                    *앱 재실행 후에도 값이 고정됩니다.
                  </div>
                </div>
                <div className="flex flex-wrap py-4 border-b">
                  {Object.keys(REGION).map((area) => (
                    <Chip
                      className="mr-3 mb-3"
                      key={area}
                      text={REGION[area as keyof typeof REGION]}
                      active={selectedAreaTags.has(area)}
                      onClick={handleAreaTagClick(area)}
                    />
                  ))}
                </div>
              </>

              <>
                <div className="flex items-center space-x-2 mt-7">
                  <H2>종목 선택</H2>
                </div>
                <div className="flex flex-wrap py-4 border-b">
                  {Object.keys(CATEGORY).map((category) => (
                    <Chip
                      className="mr-3 mb-3"
                      key={category}
                      text={CATEGORY[category as keyof typeof CATEGORY]}
                      active={selectedCategoryTags.has(category)}
                      onClick={handleCategoryTagClick(category)}
                    />
                  ))}
                </div>
              </>

              <>
                <div className="flex items-center space-x-2 mt-7">
                  <H2>희망 튜터성별</H2>
                </div>
                <div className="flex flex-wrap py-4 border-b">
                  {Object.keys(GENDER_TYPE).map((gender) => (
                    <Chip
                      className="mr-3 mb-3"
                      key={gender}
                      text={GENDER_TYPE[gender as keyof typeof GENDER_TYPE]}
                      active={selectedGenderTags.has(gender)}
                      onClick={handleGenderTagClick(gender)}
                    />
                  ))}
                </div>
              </>
              <Button
                text="적용하기"
                className="w-full filled-brand-black"
                onClick={apply}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
