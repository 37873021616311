import React from 'react';
import { H1 } from './H1';
import { Icon } from './Icon';
import { User } from '../types';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { Button } from './Button';
import { parseNewLine, sanitizeTag } from '../utils';
import { useUser } from '../contexts/UserContext';

interface Item {
  id: number;
  user?: User;
  createdAt: string;
  description: string;
}

interface TutorProposalModalProps {
  item: Item;
  open: boolean;
  onClose: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
}

export const TutorProposalModal: React.FC<TutorProposalModalProps> = ({
  item,
  open,
  onClose,
  onDelete,
  onEdit,
}) => {
  const { id: userId } = useUser();

  if (!item.user) {
    return null;
  }

  const onChatClick = () => {
    if (!item.user) {
      return;
    }

    (window as any).ReactNativeWebView.postMessage(
      JSON.stringify({
        action: 'chat-request',
        data: {
          respondent: {
            id: item.user.id,
            name: item.user.name,
            type: item.user.role,
          },
          lessonRequestResponseId: item.id,
        },
      })
    );
  };

  const onTutorReviewClick = () => {
    if (!item.user) {
      return;
    }

    (window as any).ReactNativeWebView.postMessage(
      JSON.stringify({
        action: 'tutor-review-summary',
        data: {
          tutor: {
            id: item.user.id,
          },
        },
      })
    );
  };

  return (
    <>
      {open && (
        <>
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity z-10"
            onClick={onClose}
            aria-hidden="true"
          ></div>
          <div className="bg-white p-5 w-full h-5/6 fixed bottom-0 z-20 rounded-t-lg shadow-2xl">
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between mt-3">
                <H1 className="">제안 상세보기</H1>
                <Icon.X onClick={onClose} />
              </div>
              <div className="flex items-center mt-6">
                <div className="wh-12 rounded-full overflow-hidden relative">
                  <img
                    src={item.user.imagePath}
                    alt=""
                    className="w-full h-full absolute object-cover"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm font-medium">{item.user.name}</div>
                  <div className="text-xs text-brand-gray-1">
                    {utcToLocalFormat(
                      item.createdAt.toLocaleString(),
                      MomentFormat.YYYYMMDD
                    )}
                  </div>
                </div>
              </div>

              <div className="flex-1 overflow-y-scroll my-3">
                <div
                  className="text-sm font-medium"
                  dangerouslySetInnerHTML={{
                    __html: parseNewLine(sanitizeTag(item.description)),
                  }}
                />
              </div>

              <div className="w-full">
                {item.user?.id === userId ? null : (
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <Button
                      text="레슨 후기"
                      className="filled-brand-1 w-full"
                      onClick={onTutorReviewClick}
                    />

                    <Button
                      text="📩  1:1 대화 요청"
                      className="outlined-brand-1 w-full"
                      onClick={onChatClick}
                    />
                  </div>
                )}

                {onDelete && onEdit ? (
                  <div className="grid grid-cols-2 gap-4">
                    <Button
                      text="삭제"
                      className="outlined-brand-1 w-full"
                      onClick={onDelete}
                    />

                    <Button
                      text="수정하기"
                      className="filled-brand-1 w-full"
                      onClick={onEdit}
                    />
                  </div>
                ) : (
                  <Button
                    text="돌아가기"
                    className="filled-brand-black w-full"
                    onClick={onClose}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
