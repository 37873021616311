import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { HomePage } from './HomePage';
import { AddUserPost } from './UserPost/AddUserPost';
import { UserPostDetail } from './UserPost/UserPostDetail';
import { EditUserPost } from './UserPost/EditUserPost';
import { AddTutorPost } from './TutorPost/AddTutorPost';
import { TutorPostDetail } from './TutorPost/TutorPostDetail';
import { EditTutorPost } from './TutorPost/EditTutorPost';

export const Router = () => {
  return (
    <Switch>
      {/* <Route path="/post/my" component={HomePage} exact /> */}
      <Route path="/post/tutor/add" component={AddTutorPost} />
      <Route path="/post/tutor/:id/edit" component={EditTutorPost} />
      <Route path="/post/tutor/:id" component={TutorPostDetail} />
      <Route path="/post/user/add" component={AddUserPost} />
      <Route path="/post/user/:id/edit" component={EditUserPost} />
      <Route path="/post/user/:id" component={UserPostDetail} />
      <Route path="/post" component={HomePage} />
      <Route path="/">
        <Redirect to="/post/user" />
      </Route>
    </Switch>
  );
};
