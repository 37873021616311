import React, { useEffect } from 'react';
import { Button } from '../components/Button';

import Empty from '../components/Empty';
import Loading from '../components/Loading';
import { TutorPostCard } from '../components/TutorPostCard';
import { UserPostCard } from '../components/UserPostCard';
import { useUser } from '../contexts/UserContext';
import useLessonRequestList from '../hooks/useLessonRequestList';

const PARAMS = {
  excludeFilter: {},
  filterParams: {},
  type: 'myList' as const,
};

export const MyPost = () => {
  const { isLoading, posts, loadMore, isLoadingMore, yOffset } =
    useLessonRequestList(PARAMS);
  const { isStudent } = useUser();

  useEffect(() => {
    window.scrollTo(0, yOffset);
  }, [yOffset]);

  useEffect(() => {
    if (posts.length !== 0) {
      sessionStorage.setItem('posts', JSON.stringify(posts));
    }
  }, [posts]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      {posts.length === 0 ? (
        <Empty />
      ) : (
        <>
          {posts.map((post) =>
            isStudent ? (
              <UserPostCard item={post} />
            ) : (
              <TutorPostCard item={post} />
            )
          )}

          <div className="flex place-content-center">
            <Button text="더 보기" onClick={loadMore} loading={isLoadingMore} />
          </div>
        </>
      )}
    </div>
  );
};
