import React from 'react';
import { useHistory } from 'react-router';

import { Topbar } from '../../components/Topbar';
import useImageUpload from '../../hooks/useImageUpload';
import useLessonRequestCreate from '../../hooks/useLessonRequestCreate';
import UserPostForm, { INewLessonRequest } from '../UserPost/UserPostForm';

const HIDDEN_ATTRS = new Set<keyof INewLessonRequest>(['closed']);
const REQUIRED_ATTRS = new Set<keyof INewLessonRequest>([
  'title',
  'body',
  'category',
  'region',
  'tutor_gender',
  'price',
  'private',
  'closed',
  'terms_agreed_at',
  'image_url',
]);

export const AddTutorPost = () => {
  const { push } = useHistory();
  const { createLessonRequest } = useLessonRequestCreate();
  const { handleImageChange, isUploading } = useImageUpload();

  const submit = async (lessonRequest: INewLessonRequest) => {
    try {
      await createLessonRequest(lessonRequest);
    } catch (error) {
      alert('저장 중 에러가 발생했습니다. ' + (error as Error).message);
      return;
    }

    push('/post/tutor');
  };

  return (
    <>
      <Topbar text="레슨 모집 작성" back={true} />

      <UserPostForm
        onSubmit={submit}
        hiddenAttrs={HIDDEN_ATTRS}
        requiredAttrs={REQUIRED_ATTRS}
        onImageChange={handleImageChange}
        isImageUploading={isUploading}
      />
    </>
  );
};
