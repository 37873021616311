import { ButtonHTMLAttributes, FC } from 'react';
interface ChipProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  active?: boolean;
  onClick?: () => void;
}

export const Chip: FC<ChipProps> = ({ className, text, active, onClick }) => {
  return (
    <div
      className={`text-sm px-3 py-1.5 rounded-full ${
        active ? 'bg-brand-1 text-white' : 'bg-gray-200 text-gray-600'
      } ${className}`}
      onClick={onClick}
    >
      {text}
    </div>
  );
};
