import React from 'react';

import { Topbar } from '../../components/Topbar';
import { ReactComponent as Lock } from '../../assets/svg/lock_icon.svg';
import { H3 } from '../../components/H3';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { useHistory, useParams } from 'react-router';
import { Button } from '../../components/Button';
import useLessonRequest from '../../hooks/useLessonRequest';
import { useUser } from '../../contexts/UserContext';
import { isVideo, parseNewLine, sanitizeTag } from '../../utils';
import { api } from '../../plugins/axios';

export const TutorPostDetail = () => {
  const params = useParams<{ id?: string }>();
  const { push } = useHistory();

  const { id: userId, isStudent, isTutor } = useUser();
  const { post } = useLessonRequest(userId, params.id);

  if (!post) {
    return null;
  }

  const isVideoFile = isVideo(post.filePath);

  const onChatClick = async () => {
    if (!post) {
      return;
    }

    try {
      await api.post(`/lesson_requests/${params.id}/view_count`);
    } catch (error) {
      throw new Error((error as Error).message);
    } finally {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          action: 'chat-request-by-student',
          data: {
            requester: {
              id: post.requesterId,
            },
          },
        })
      );
    }
  };

  const onRequesterClick = () => {
    if (!post.requester) {
      alert('유저 정보가 없습니다. 다시 시도해주세요.');
    }
    (window as any).ReactNativeWebView.postMessage(
      JSON.stringify({
        action: 'view-tutor-profile',
        data: {
          tutor: {
            id: post.requesterId,
          },
        },
      })
    );
  };

  return (
    <>
      <Topbar
        text="상세보기"
        back={true}
        rightText={post.hasPost ? '수정' : undefined}
        rightOnClick={() => push(`/post/tutor/${post.id}/edit`)}
      />

      <div>
        <div
          className="flex items-center p-4 justify-between text-sm"
          onClick={onRequesterClick}
        >
          <div className="flex items-center">
            <img
              className="wh-10 rounded-full"
              src={post.requester.thumbnail_url}
              alt=""
            />
            <div className="px-2">{post.requester.name}</div>
          </div>
          <p>프로필 보기 {'>'}</p>
        </div>

        <div className="bg-gray-100 h-px mb-1"></div>
        <div className="p-4 space-y-5">
          <div>
            <div className="flex items-center space-x-2 text-xs mb-2">
              <label
                className={`px-3 py-1.5 rounded ${
                  post.isOngoing
                    ? 'bg-brand-1 text-white'
                    : 'bg-gray-200 text-gray-600'
                }`}
              >
                {post.isOngoing ? '모집중' : '마감'}
              </label>
              {post.hasPost && (
                <label className="px-3 py-1.5 bg-black text-white rounded">
                  내가쓴 글
                </label>
              )}
              {post.private && isTutor && <Lock />}
            </div>
            <H3>{post.title}</H3>
            <div className="text-sm text-brand-gray-2">
              {utcToLocalFormat(
                post.createdAt.toLocaleString(),
                MomentFormat.YYYYMMDD
              )}
            </div>
          </div>

          <div className="text-brand-1 text-sm">
            <div>
              {post.category} | {post.area}
            </div>
            <div>비용 : {post.price}</div>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: parseNewLine(sanitizeTag(post.description)),
            }}
          />
        </div>
      </div>

      {isVideoFile ? (
        <div className="relative w-full">
          <video src={`${post.filePath}#t=0.1`} controls={true} />
        </div>
      ) : (
        post.filePath && (
          <div className="relative w-full">
            <img src={post.filePath} alt="" />
          </div>
        )
      )}

      {isStudent && post.isOngoing ? (
        <div className="fixed bottom-4 left-0 px-4 w-full">
          <Button
            text="📩 1:1 대화 요청"
            className="filled-brand-1 w-full"
            onClick={onChatClick}
          />
        </div>
      ) : null}
    </>
  );
};
