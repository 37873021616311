import React, { useState } from 'react';

import { H1 } from './H1';
import { Icon } from './Icon';
import { Button } from './Button';
import { TextArea } from './TextArea';
import useLessonRequestResponseCreate from '../hooks/useLessonRequestResponseCreate';

interface TutorProposalAddModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  lessonRequestId?: string | number;
}

const PROPOSAL_EXAMPLE =
  'ex) 안녕하세요, KPGA 홍길동 프로입니다.\n맨몸 트레이닝, 스윙자세 개선, 비거리 향상, 숏게임 레슨 등 모든 골프레슨 가능합니다!\n\n저는 주로 TPZ 신사점에서 레슨중이며,\n레슨비용은 1회 N원, 10회 N원이며 TPZ이용료는 별도입니다.\n희망하시면 TPZ 앱으로 1:1 대화 걸어주세요!';

export const TutorProposalAddModal: React.FC<TutorProposalAddModalProps> = ({
  open,
  onClose,
  onSuccess,
  lessonRequestId,
}) => {
  const [body, setBody] = useState('');
  const { createResponse, loading } = useLessonRequestResponseCreate();

  const submit = async () => {
    if (!lessonRequestId) {
      return;
    }

    try {
      const confirmation = window.confirm(
        '레슨 제안하기 - 선택한 유저에게 레슨을 제안하시겠습니까? 제안할 시 유저에게 튜터의 정보가 노출됩니다.'
      );

      if (!confirmation) {
        return;
      }

      await createResponse(lessonRequestId, body);

      onSuccess();
    } catch (e) {
      alert((e as Error).message);
    }
  };

  const handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBody(e.currentTarget.value);
  };

  return (
    <>
      {open && (
        <>
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity z-10"
            onClick={onClose}
            aria-hidden="true"
          ></div>
          <div className="bg-white p-5 w-full h-5/6 fixed bottom-0 z-20 rounded-t-lg shadow-2xl space-y-5">
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between mt-3">
                <H1 className="">제안하기</H1>
                <Icon.X onClick={onClose} />
              </div>

              <TextArea
                parentsClassname="h-full flex-1 my-4"
                label="*제안 내용"
                className="h-full"
                onChange={handleBodyChange}
                placeholder={PROPOSAL_EXAMPLE}
              />
              <div className="text-xs text-red-500 mb-4">
                *레슨 제안 시, 주의사항!! 본 레슨 요청서의 내용과 맞지 않는
                제안서를 작성 시, 관리자에 의해 삭제될 수 있습니다.
              </div>

              <div className="w-full">
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <Button
                    text="취소"
                    className="outlined-brand-1 bg-white w-full"
                    onClick={onClose}
                  />
                  <Button
                    disabled={!body.trim()}
                    text="제안하기"
                    className="filled-brand-1 w-full"
                    onClick={submit}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
