import React, { ButtonHTMLAttributes, FC } from 'react';
import { useHistory } from 'react-router';
import Spinner from './Spinner';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  loading?: boolean;
  text?: string;
  to?: string;
}

export const Button: FC<ButtonProps> = ({
  children,
  className = '',
  disabled,
  loading,
  onClick,
  text,
  to,
  ...props
}) => {
  const { push } = useHistory();

  return (
    <button
      className={`button inline-flex items-center justify-center px-4 py-2 text-base ease-in-out ${className}`}
      disabled={loading || disabled}
      onClick={to ? () => push(to) : onClick}
      {...props}
    >
      {loading ? <Spinner /> : null}
      {text ?? children}
    </button>
  );
};
