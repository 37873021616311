const FILE_TYPES: { [k in string]: string } = {
  '3gp': 'video/3gp',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  m4v: 'video/m4v',
  mkv: 'video/mkv',
  mov: 'video/mov',
  mp4: 'video/mp4',
  png: 'image/png',
  ts: 'video/ts',
  webm: 'video/webm',
};

export function range(length: number, start = 0) {
  return Array.from({ length }, (_, i) => i + start);
}

export const sanitizeTag = (source: string) =>
  source.replace(/>/g, '&gt;').replace(/</, '&lt;');

export const parseNewLine = (source: string) => source.replace(/\n/g, '<br />');

export const getExtensionFromPath = (path: string): string => {
  return (path.match(/.*\.([a-zA-Z0-9]{3,4}).*/) || [])[1];
};

export const getFileNameFromPath = (path: string): string => {
  return (path.match(/.*\/(.*\.[a-zA-Z0-9]{3,4}).*/) || [])[1];
};

export const getFileTypeFromPath = (path: string): string => {
  const ext = getExtensionFromPath(path).toLowerCase();

  return FILE_TYPES[ext];
};

export const isVideo = (filename?: string) =>
  filename ? /(mov|avi|wmv|flv|3gp|mp4|mpg)$/i.test(filename) : false;

export const resetPostSession = () => {
  sessionStorage.removeItem('yOffset');
  sessionStorage.removeItem('posts');
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
