import { useState } from 'react';

import { useUser } from '../contexts/UserContext';
import { INewLessonRequest } from '../pages/UserPost/UserPostForm';
import { api } from '../plugins/axios';

const useLessonRequestCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id: requester_id } = useUser();

  const createLessonRequest = async (lessonRequest: INewLessonRequest) => {
    setIsLoading(true);

    try {
      await api.post('/lesson_requests', {
        lesson_request: { ...lessonRequest, requester_id },
      });
    } catch (error) {
      throw new Error((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    createLessonRequest,
  };
};

export default useLessonRequestCreate;
