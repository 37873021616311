import { FC, HTMLAttributes } from 'react';

export interface H3Props extends HTMLAttributes<HTMLHeadingElement> {}

export const H3: FC<H3Props> = ({ children, className, onClick }) => {
  return (
    <h3 className={`h3 ${className}`} onClick={onClick}>
      {children}
    </h3>
  );
};
