import { ILessonRequest } from '@theplaza/types';

import { CATEGORY, GENDER_TYPE } from './constants';
import { Item } from './types';

const lessonRequestToPost =
  (userId: number) =>
  (lessonRequest: ILessonRequest): Item => ({
    id: lessonRequest.id,
    isOngoing: !lessonRequest.closed,
    private: lessonRequest.private,
    hasPost: userId === lessonRequest.requester_id,
    title: lessonRequest.title,
    description: lessonRequest.body,
    category: CATEGORY[lessonRequest.category],
    area: lessonRequest.region,
    gender: GENDER_TYPE[lessonRequest.tutor_gender],
    createdAt: lessonRequest.created_at,
    closedAt: lessonRequest.closed_at,
    price: lessonRequest.price,
    requesterId: lessonRequest.requester_id,
    requester: lessonRequest.requester,
    volunteerCount: lessonRequest.responses_count || 0,
    volunteer: lessonRequest.responses
      ? lessonRequest.responses.map((response) => ({
          id: response.id,
          createdAt: response.created_at,
          description: response.body,
          user: response.respondent
            ? {
                id: response.respondent.id,
                createdAt: '',
                name: response.respondent.name,
                email: '',
                imagePath: response.respondent.thumbnail_url,
                role: response.respondent.user_type,
                updatedAt: '',
              }
            : undefined,
        }))
      : undefined,
    filePath: lessonRequest.image_url,
    videoThumbnailPath: lessonRequest.thumb_url,
  });

export default lessonRequestToPost;
