import Spinner from './Spinner';

const Loading = () => {
  return (
    <div className="flex h-screen pb-40 justify-center items-center">
      <Spinner color="text-black" />
    </div>
  );
};

export default Loading;
