import axios from 'axios';
import { tokenState } from './ridge';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
  const token = tokenState.get();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    delete config.headers.Authorization;
  }
  return config;
});

api.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    tokenState.reset();
  }
  return Promise.reject(error);
});

const setupApi = () => {
  const API_BASE_URL = localStorage.getItem('apiBaseUrl');
  const accessToken = localStorage.getItem('token');

  if (!API_BASE_URL || !accessToken) {
    return;
  }

  api.defaults.baseURL = API_BASE_URL;
  api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
};

export { api, setupApi };
