const Empty = () => {
  return (
    <div className="flex h-screen pb-60 justify-center items-center">
      <div className="text-black text-opacity-50">
        <p>관련 데이터가 없습니다.</p>
      </div>
    </div>
  );
};

export default Empty;
