import { useEffect, useState } from 'react';

import { api } from '../plugins/axios';

export enum BannerAdPlaceType {
  tutor_list = 'tutor_list',
  time_select = 'time_select',
  lesson_request = 'lesson_request',
}

export interface IBannerAd {
  id: number;
  name: string;
  order: number;
  place: BannerAdPlaceType;
  image_url: string;
  link_to_url?: string;
  link_to_user_id?: number;
  created_at: string;
  updated_at: string;
}

const useBannerAdList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [bannerAds, setBannerAds] = useState<IBannerAd[]>([]);

  useEffect(() => {
    setIsLoading(true);

    api
      .get<IBannerAd[]>('/banner_ads')
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }

        throw new Error('데이터 로딩 중 오류가 발생했습니다.');
      })
      .then((data) => {
        setBannerAds(
          data.filter((bannerAd) => bannerAd.place === 'lesson_request')
        );
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { isLoading, bannerAds };
};

export default useBannerAdList;
