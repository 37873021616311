import React, { useState } from 'react';

import { H1 } from './H1';
import { Icon } from './Icon';
import { Button } from './Button';
import { TextArea } from './TextArea';
import { Volunteer } from '../types';
import useLessonRequestResponseUpdate from '../hooks/useLessonRequestResponseUpdate';

interface TutorProposalEditModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  initialValue?: Volunteer;
}

export const TutorProposalEditModal: React.FC<TutorProposalEditModalProps> = ({
  open,
  onClose,
  onSuccess,
  initialValue,
}) => {
  const [body, setBody] = useState('');
  const { updateResponse, loading } = useLessonRequestResponseUpdate();

  const submit = async () => {
    if (!initialValue) {
      return;
    }

    try {
      await updateResponse(initialValue.id, body);

      onSuccess();
    } catch (e) {
      alert((e as Error).message);
    }
  };

  const handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBody(e.currentTarget.value);
  };

  return (
    <>
      {open && (
        <>
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity z-10"
            onClick={onClose}
            aria-hidden="true"
          ></div>
          <div className="bg-white p-5 w-full h-5/6 fixed bottom-0 z-20 rounded-t-lg shadow-2xl space-y-5">
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between mt-3">
                <H1 className="">제안하기</H1>
                <Icon.X onClick={onClose} />
              </div>
              <TextArea
                label="*제안 내용"
                parentsClassname="h-full flex-1 my-4"
                className="h-full"
                onChange={handleBodyChange}
                defaultValue={initialValue?.description || ''}
              />
              <div className="w-full">
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <Button
                    text="취소"
                    className="outlined-brand-1 w-full"
                    onClick={onClose}
                  />
                  <Button
                    disabled={!body.trim()}
                    text="수정하기"
                    className="filled-brand-1 w-full"
                    onClick={submit}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
