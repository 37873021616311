import React from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as Lock } from '../assets/svg/lock_icon.svg';
import { useUser } from '../contexts/UserContext';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { Item } from '../types';
import { H3 } from './H3';

interface UserPostCardProps {
  item: Item;
}

export const UserPostCard: React.FC<UserPostCardProps> = ({ item }) => {
  const { push } = useHistory();
  const { isStudent } = useUser();

  const onPostCardClick = () => {
    if (!item.hasPost && item.private && isStudent) {
      alert('비밀글로 작성자만 볼 수 있습니다');
    } else if (!item.hasPost && !item.isOngoing) {
      alert('이미 마감된 글입니다');
    } else {
      sessionStorage.setItem('yOffset', window.pageYOffset.toString());
      push(`/post/user/${item.id}`);
    }
  };

  return (
    <>
      <div
        className="px-4 py-5 border-b border-gray-100 text-sm"
        onClick={onPostCardClick}
      >
        <div className="flex justify-between items-center mb-3">
          <div className="flex items-center space-x-2 text-xs">
            <label
              className={`px-3 py-1.5 rounded ${
                item.isOngoing
                  ? 'bg-brand-1 text-white'
                  : 'bg-gray-200 text-gray-600'
              }`}
            >
              {item.isOngoing ? '모집중' : '마감'}
            </label>
            {item.hasPost && (
              <label className="px-3 py-1.5 bg-black text-white rounded">
                내가쓴 글
              </label>
            )}
            {item.private && isStudent && <Lock />}
          </div>
          <div className="text-sm text-brand-gray-2">
            {utcToLocalFormat(
              item.createdAt.toLocaleString(),
              MomentFormat.YYYYMMDD
            )}
          </div>
        </div>
        <H3 className="mb-1">{item.title}</H3>
        <div className="flex items-center justify-between">
          <div className="text-brand-gray-2 font-light">
            {item.category} | {item.area} | {item.gender}
          </div>
          <div className="text-brand-1">{item.volunteerCount}명 제안</div>
        </div>
      </div>
    </>
  );
};
