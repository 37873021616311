import Slider from 'react-slick';
import { Route, Switch, useLocation } from 'react-router-dom';

import { Tab } from '../components/Tab';
import { TutorPostList } from './TutorPost/TutorPostList';
import { UserPostList } from './UserPost/UserPostList';
import { MyPost } from './MyPost';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useBannerAdList from '../hooks/useBannerAdList';

const TABS = [
  {
    text: '레슨요청',
    tabName: '/post/user',
    to: '/post/user',
  },
  {
    text: '레슨모집',
    tabName: '/post/tutor',
    to: '/post/tutor',
  },
  {
    text: '내가쓴 글',
    tabName: '/post/my',
    to: '/post/my',
  },
];

const SETTINGS = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <></>,
  prevArrow: <></>,
  // initialSlide: 0,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2500,
  dotsClass: 'dots-css',
};

export const HomePage = () => {
  const { pathname } = useLocation();
  const { bannerAds } = useBannerAdList();

  const onBannerClick = (url?: string) => () => {
    if (!url) {
      return;
    }

    (window as any).ReactNativeWebView.postMessage(
      JSON.stringify({
        action: 'open-external-url',
        data: { url },
      })
    );
  };

  return (
    <>
      {bannerAds.length !== 0 && (
        <Slider {...SETTINGS} className="z-10 h-24">
          {bannerAds.map((banner) => (
            <div key={banner.id}>
              <div className="relative w-full h-24">
                <img
                  className="absolute w-full h-full object-cover"
                  src={banner.image_url}
                  alt=""
                  onClick={onBannerClick(banner.link_to_url)}
                />
              </div>
            </div>
          ))}
        </Slider>
      )}

      <div className="w-full">
        <div className="w-full flex sticky z-10 pt-4 top-0 bg-white">
          {TABS.map((tab) => (
            <Tab
              key={tab.text}
              text={tab.text}
              to={tab.to}
              selected={pathname.startsWith(tab.tabName)}
            />
          ))}
        </div>

        <Switch>
          <Route path="/post/my" component={MyPost} />
          <Route path="/post/tutor" component={TutorPostList} />

          <Route path="/post/user" component={UserPostList} />
        </Switch>
      </div>
    </>
  );
};
