import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { FilterBar } from '../../components/FilterBar';
import { TutorPostCard } from '../../components/TutorPostCard';
import {
  convertFilterToParams,
  FilterModal,
  FilterModalProps,
  getFilterFromStorage,
} from '../../components/FilterModal';
import useLessonRequestList from '../../hooks/useLessonRequestList';
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import { useUser } from '../../contexts/UserContext';
import { Icon } from '../../components/Icon';
import { Button } from '../../components/Button';
import { SCREEN } from '../../constants';

export const TutorPostList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [excludeFilter, setExcludeFilter] = useState<{ closed?: boolean }>({});
  const [filterParams, setFilterParams] = useState<{
    [key: string]: string;
  }>({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isFilterInitialized, setIsFilterInitialized] = useState(false);

  const { isLoading, posts, loadMore, isLoadingMore, yOffset } =
    useLessonRequestList({
      excludeFilter,
      filterParams,
      requesterUserType: 'tutor',
      isFilterInitialized,
    });
  const { push } = useHistory();
  const { isTutor } = useUser();

  useEffect(() => {
    const filterData = getFilterFromStorage(SCREEN.tutor);
    const params = convertFilterToParams(filterData);
    setFilterParams(params);
    setIsFilterInitialized(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, yOffset);
  }, [yOffset]);

  useEffect(() => {
    if (posts.length !== 0) {
      sessionStorage.setItem('posts', JSON.stringify(posts));
    }
  }, [posts]);

  useEffect(() => {
    if (Object.keys(filterParams).length === 0) {
      setIsFilterApplied(false);
    } else {
      setIsFilterApplied(true);
    }
  }, [filterParams]);

  if (isLoading) {
    return <Loading />;
  }

  const handleClosedChange: InputHTMLAttributes<HTMLInputElement>['onChange'] =
    () => {
      setExcludeFilter({ closed: !excludeFilter.closed });
    };

  const handleFilterChange: FilterModalProps['onFilterChange'] = (filter) => {
    const params = convertFilterToParams(filter);
    setFilterParams(params);
  };

  return (
    <div className="pb-24">
      <FilterModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onFilterChange={handleFilterChange}
        screen={SCREEN.tutor}
      />

      <FilterBar
        filter={excludeFilter}
        onClosedChange={handleClosedChange}
        onClick={() => setIsOpen(true)}
        isFilterApplied={isFilterApplied}
      />

      {posts.length === 0 ? (
        <Empty />
      ) : (
        <>
          {posts.map((post) => (
            <TutorPostCard item={post} />
          ))}

          <div className="flex place-content-center">
            <Button text="더 보기" onClick={loadMore} loading={isLoadingMore} />
          </div>
        </>
      )}

      {isTutor ? (
        <button
          className="bg-brand-1 px-5 py-3 flex space-x-2 text-white rounded-full fixed bottom-7 left-1/2 transform -translate-x-1/2 shadow-md "
          onClick={() => push('/post/tutor/add')}
        >
          <Icon.Plus className="" />
          <div>레슨 모집하기</div>
        </button>
      ) : null}
    </div>
  );
};
